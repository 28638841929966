import { Container, Group, Anchor } from "@mantine/core";
import { Logo } from "../Logo/Logo";
import classes from "./Footer.module.css";

const links = [
  { link: "/", label: "Home" },
  { link: "/register", label: "Register" },
  { link: "/login", label: "Login" },
];

export function Footer() {
  const items = links.map((link) => (
    <Anchor<"a"> c="dimmed" key={link.label} href={link.link} size="sm">
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Group className={classes.links}>{items}</Group>
        <Logo size={"small"} />
      </Container>
    </div>
  );
}
