import { ChangeEvent } from "react";
import { Text, Button, Input, Anchor, LoadingOverlay } from "@mantine/core";
import { useState, useEffect } from "react";
import { Releases } from "../Releases/Releases";
import { Logo } from "../Logo/Logo";

const ExternalDataSearch = ({
  onAddToLibrary,
  selectedRelease,
  isLoading,
  added = [],
  inLibrary = [],
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [searching, setSearching] = useState(false);

  const handleChange = (event: ChangeEvent) => {
    setSearchTerm((event.target as HTMLInputElement).value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchTerm((event.target as HTMLInputElement).value);
      setSearching(true);
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0 && searching) {
      const doSearch = async () => {
        const response = await fetch("/api/search?term=" + searchTerm);
        const results = await response.json();
        setSearchResults(results);
        setSearching(false);
      };

      doSearch();
    }
  });

  const searchHandler = (event) => {
    event.preventDefault();
    setSearchTerm((event.target.search as HTMLInputElement).value);
    setSearching(true);
    event.target.search.value = "";
  };

  return (
    <>
      <Logo />
      <LoadingOverlay visible={searching} />
      <Text
        color="dimmed"
        align="center"
        size="lg"
        sx={{ maxWidth: 580 }}
        mx="auto"
        mt="xl"
      >
        Search by artist and start organising your records
        <form onSubmit={searchHandler}>
          <Input
            autoComplete="off"
            name="search"
            placeholder="Search"
            type="text"
            onKeyDown={handleKeyDown}
            mt={10}
          />
          <Button type="submit" mt={10}>
            {searching ? `Searching...` : `Search`}
          </Button>
        </form>
      </Text>

      {searchResults && (
        <>
          <Text align="center" mt={10}>
            <Anchor
              onClick={() => {
                setSearchTerm("");
                setSearchResults(null);
              }}
            >
              Clear search
            </Anchor>
          </Text>
          <Releases
            releases={searchResults}
            title={`Search Results for ${searchTerm}`}
            onPrimaryCTA={onAddToLibrary}
            primaryCTA="Add to Library"
            selectedRelease={selectedRelease}
            isLoading={isLoading}
            added={added}
            inLibrary={inLibrary}
          />
        </>
      )}
    </>
  );
};

export { ExternalDataSearch };
