import { Anchor, Card, Group, Text } from "@mantine/core";

const ArticleCard = ({ slug, title }) => {
  return (
    <Card shadow="sm" p="lg" style={{ width: 250, margin: "auto" }}>
      <Anchor color={"#000000"} underline={false} href={`/article/${slug}`}>
        <Group style={{ marginBottom: 5, marginTop: 5, minHeight: 50 }}>
          <Text weight={600} style={{ maxHeight: 60 }}>
            {title}
          </Text>
        </Group>
      </Anchor>
    </Card>
  );
};

export { ArticleCard };
