import { NextPage } from "next";
import React, { useState } from "react";
import { Container } from "@mantine/core";
import { withIronSessionSsr } from "iron-session/next";
import { getUser, sessionOptions } from "../lib/session";
import { MainLayout } from "../components/Layouts/MainLayout";
import { ExternalDataSearch } from "../components/ExternalDataSearch/ExternalDataSearch";
import { getTrendingReleases } from "../model/TrendingReleases/getTrendingReleases";
import { onAddToLibrary } from "../lib/handlers/onAddToLibrary";
import { AlbumCarousel } from "../components/AlbumCarousel/AlbumCarousel";
import { getLatestArticles } from "../model/Articles/getLatestArticles";
import { ArticleCarousel } from "../components/Article/ArticleCarousel";
import { getSiteUrl } from "../lib/utils/getSiteUrl";
import Head from "next/head";

interface Props {
  trending?: any;
  user?: any;
  articles?: any;
}

const HomePage: NextPage<Props> = ({ trending, user, articles }) => {
  const [selectedRelease, setSelectedRelease] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [added, setAdded] = useState([]);
  const [inLibrary, setInLibrary] = useState([]);

  return (
    <MainLayout user={user}>
      <Head>
        <link rel="alternate" href={`${getSiteUrl()}`} hrefLang="en" />
      </Head>

      <Container size="lg" px="lg">
        <ExternalDataSearch
          onAddToLibrary={onAddToLibrary(
            setSelectedRelease,
            setIsLoading,
            setAdded,
            setInLibrary,
            added,
            inLibrary,
            user
          )}
          selectedRelease={selectedRelease}
          isLoading={isLoading}
          added={added}
          inLibrary={inLibrary}
        />

        <AlbumCarousel
          albums={trending}
          albumCardProps={{
            onPrimaryCTA: onAddToLibrary(
              setSelectedRelease,
              setIsLoading,
              setAdded,
              setInLibrary,
              added,
              inLibrary,
              user
            ),
            primaryCTA: "Add to Library",
            selectedRelease: selectedRelease,
            isLoading: isLoading,
            added: added,
            inLibrary: inLibrary,
          }}
          title={"Trending UK"}
        />
        <ArticleCarousel articles={articles} title={`Latest Articles`} />
      </Container>
    </MainLayout>
  );
};

export const getServerSideProps = withIronSessionSsr(async ({ req, res }) => {
  const user = (await getUser(req, res)) || null;
  const trending = await getTrendingReleases();
  const articles = await getLatestArticles();

  return { props: { trending, user, articles } };
}, sessionOptions);

export default HomePage;
