import { Center, Image } from "@mantine/core";

const Logo = ({ size = "default" }) => {
  let imageUrl = "/images/new-logo-3.png";

  if (size === "small") {
    imageUrl = "/images/small-logo.png";
  }

  return (
    <Center>
      <Image src={imageUrl} maw={400} w={"auto"} fit={"contain"} radius={20} />
    </Center>
  );
};

export { Logo };
