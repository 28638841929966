import { Grid, Title } from "@mantine/core";
import { AlbumCard } from "../AlbumCard/AlbumCard";

const Releases = ({
  releases,
  title = null,
  primaryCTA = null,
  onPrimaryCTA = null,
  selectedRelease = null,
  isLoading = false,
  added = [],
  deleted = [],
  inLibrary = [],
}) => {
  if (releases.length > 0) {
    return (
      <>
        {title && (
          <Title
            order={2}
            style={{ marginTop: 50, marginBottom: 10 }}
            align="center"
          >
            {title}
          </Title>
        )}
        <Grid gutter={20} mt={10}>
          {releases.map((release) => (
            <Grid.Col
              xs={6}
              sm={4}
              md={4}
              lg={3}
              key={release.mid}
              hidden={deleted.indexOf(release.mid) > -1}
            >
              <AlbumCard
                release={release}
                onPrimaryCTA={onPrimaryCTA}
                primaryCTA={primaryCTA}
                isLoading={isLoading}
                added={added}
                inLibrary={inLibrary}
                selectedRelease={selectedRelease}
              />
            </Grid.Col>
          ))}
        </Grid>
      </>
    );
  } else {
    return null;
  }
};

export { Releases };
