import {
  Anchor,
  Button,
  Card,
  Group,
  Image,
  LoadingOverlay,
  Text,
} from "@mantine/core";
import slugify from "slugify";

const slugifyOptions = {
  lower: true,
  strict: true,
  trim: true,
};

const AlbumCard = ({
  release,
  onPrimaryCTA,
  primaryCTA,
  isLoading = false,
  selectedRelease,
  added,
  inLibrary,
}) => {
  return (
    <Card shadow="sm" p="lg" style={{ width: 250, margin: "auto" }}>
      <Anchor
        color={"#000000"}
        underline={false}
        href={`/album/${slugify(release.title, slugifyOptions)}-${slugify(
          release.artist,
          slugifyOptions
        )}-${release.albumId}-${release.providerId}`}
      >
        <Card.Section style={{ textAlign: "center" }}>
          <Image
            src={release?.coverUrl?.replace("/upload", "/upload/c_fill,w_250")}
            width={250}
            alt={`Album cover for ${release.title} - ${release.artist}`}
          />
        </Card.Section>
        <Group
          direction="row"
          style={{ marginBottom: 5, marginTop: 5, minHeight: 50 }}
        >
          <Text weight={500} style={{ maxHeight: 60 }}>
            {release.title}
            <br />
            {release.artist}
          </Text>
        </Group>
      </Anchor>
      <LoadingOverlay
        visible={selectedRelease?.albumId === release.albumId && isLoading}
      />
      {onPrimaryCTA && (
        <Button
          disabled={
            added.indexOf(release.albumId) > -1 ||
            inLibrary.indexOf(release.albumId) > -1
          }
          onClick={() => onPrimaryCTA(release)}
          variant="light"
          color="blue"
          fullWidth
          style={{ marginTop: 14 }}
        >
          {added.indexOf(release.albumId) > -1
            ? "Added"
            : inLibrary.indexOf(release.albumId) > -1
            ? "Already in library"
            : primaryCTA}
        </Button>
      )}
    </Card>
  );
};

export { AlbumCard };
