import { Button, Group } from "@mantine/core";

const MyAccount = ({ user, visible = true }) => {
  if (!visible) {
    return null;
  }

  return (
    <Group position="center" style={{ marginTop: 20, marginBottom: 20 }}>
      {user ? (
        <>
          <Button component="a" href="/" variant="outline">
            Home
          </Button>
          <Button component="a" href="/me" variant="outline">
            Library
          </Button>
          <Button component="a" href="/shares" variant="outline">
            Shares
          </Button>
          <Button component="a" href="/logout" variant="outline">
            Logout
          </Button>
        </>
      ) : (
        <>
          <Button component="a" href="/" variant="outline">
            Home
          </Button>
          <Button component="a" href="/login" variant="outline">
            Log In
          </Button>
          <Button component="a" href="/register" variant="outline">
            Register
          </Button>
        </>
      )}
    </Group>
  );
};

export { MyAccount };
