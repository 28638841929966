import { Carousel } from "@mantine/carousel";
import { AlbumCard } from "../AlbumCard/AlbumCard";

const AlbumCarousel = ({ title, albums, albumCardProps, excludes = [] }) => {
  const carouselSlides = albums
    .filter((release) => excludes.indexOf(release.albumId) === -1)
    .map((release) => (
      <Carousel.Slide key={release.albumId}>
        <AlbumCard
          release={release}
          onPrimaryCTA={albumCardProps.onPrimaryCTA}
          primaryCTA={albumCardProps.primaryCTA}
          selectedRelease={albumCardProps.selectedRelease}
          isLoading={albumCardProps.isLoading}
          added={albumCardProps.added}
          inLibrary={albumCardProps.inLibrary}
        />
      </Carousel.Slide>
    ));

  return (
    <>
      {title && <h2 style={{ paddingLeft: "55x" }}>{title}</h2>}
      <Carousel
        height={500}
        slideSize="33.333333%"
        slideGap="md"
        align="start"
        breakpoints={[
          { maxWidth: "md", slideSize: "50%" },
          { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
        ]}
      >
        {carouselSlides}
      </Carousel>
    </>
  );
};

export { AlbumCarousel };
