import { MyAccount } from "../MyAccount/MyAccount";
import { Footer } from "../Footer/Footer";

const MainLayout = ({ children, user, hideMyAccount = false }) => {
  return (
    <div style={{ minHeight: "100%", marginBottom: "-150px" }}>
      <MyAccount user={user} visible={!hideMyAccount} />
      {children}
      <div style={{ height: "150px", clear: "both" }}></div>
      <Footer />
    </div>
  );
};

export { MainLayout };
