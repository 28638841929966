const onAddToLibrary =
  (
    setSelectedRelease,
    setIsLoading,
    setAdded,
    setInLibrary,
    added,
    inLibrary,
    user
  ) =>
  async (release) => {
    if (user) {
      setSelectedRelease(release);
      setIsLoading(true);
      const response = await fetch(
        `/api/addToLibrary?id=${release.albumId}&providerId=${release.providerId}`
      );
      const result = await response.json();

      if (result.ok) {
        setAdded([...added, release.albumId]);
      } else {
        setInLibrary([...inLibrary, release.albumId]);
      }

      setSelectedRelease(null);
      setIsLoading(false);

      return result;
    } else {
      window.location.href = "/login";
    }
  };

export { onAddToLibrary };
